<script>
  import CardGrid from "components/Cards/CardGrid.svelte";
</script>

<div style="max-width:1320px;">
  <div class="flex flex-wrap">
    <div class="w-full">
      <CardGrid />
    </div>
  </div>
</div>