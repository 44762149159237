<script>
    import { onMount } from "svelte";
	import RangeSlider from "svelte-range-slider-pips";
	export let hits = [];
    export let handleSearch = ((event) => {});
    export let showFilters = false;
    export let model = [];
	let price = [200,1500];
	let waiting = false;
	$: filterOnPrice = () => {
		let res = {};
		waiting = true;
		
		//setTimeout(() => { // fake ajax call
			res.hits = [Math.floor(Math.random()*price[0]), Math.floor(price[0]+Math.random()*price[1])];
			hits = res.hits;
			waiting = false;
		//},1000);
		
	};
	
	onMount(() => {
		filterOnPrice();
	});
    
    export const colors = [
        "Rot",
        "Blau",
        "Gelb",
        "Grün",
        "Schwarz",
        "Weiß",
        "Silber",
        "Grau",
    ];
    export const manufacturers = [
        "smart",
        "Mercedes-Benz",
        "BMW",
        "Hyundai",
        "Škoda",
        "Renault",
        "VW",
        "MINI"
    ];
    export const models = [
        "218i Gran Coupé",
        "A-Klasse",
        "B-Klasse",
        "C-Klasse",
        "CLA",
        "E-Klasse",
        "EQA",
        "forfour",
        "fortwo",
        "GLB",
        "GLE",
        "i30",
        "Tucson",
        "Twingo",
        "GOLF 8",
        "Octavia",
        "ONE"
    ];
    export const types = [
        "Kombi",
        "Sportwagen",
        "T-Modelle",
        "Coupés",
        "SUVs",
        "Kleinwagen",
        "Limousinen"
    ];
    export const features = [
        "Klimaautomatik",
        "LED Frontscheinwerfer",
        "Navigationssystem",
        "Einparkhilfe",
        "Einparkhilfe Kamera",
        "Panoramadach",
        "Sitzheizung",
        "Allrad",
        "EASY-PACK Heckklappe",
    ];
    export const fuels = ["Benzin", "Diesel", "Elektro", "Hybrid"];
    export const transmissions = ["Manuell", "Automatik"];

    export function toggleFilters() {
        showFilters = !showFilters;
    }
    
    export function resetFilters() {
        model = [];
        const checkboxes = document.getElementsByName("model[]");
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
    }
</script>

{#if showFilters}

<form style="background-color: #333333; max-width: 480px; left:50px; top:100px" class="absolute w-80 z-50 left-0 px-4 py-2 rounded-lg shadow-lg bg-opacity-50 text-white">
    <!--<div>
        <label>
            <input type="checkbox" name="model[]" bind:group={model} value="" on:mousedown={resetFilters} on:mouseup={resetFilters} />
            Filter zurücksetzen
        </label>
    </div>
    <hr>-->
    <!--<div>
        <h3>Farben</h3>
        {#each colors as color}
            <label>
                <input type="checkbox" name="model[]" bind:group={model} value={color} />
                {color}
            </label>
        {/each}
    </div>-->
    <div>
        <h3>Hersteller</h3>
        {#each manufacturers as manufacturer}
            <label>
                <input type="checkbox" name="model[]" value={manufacturer} bind:group={model} on:change={handleSearch} />
                {manufacturer}
            </label>
        {/each}
    </div>
    <!--<div>
        <h3>Fahrzeugmodelle</h3>
        {#each models as model}
            <label>
                <input type="checkbox" name="model[]" value={model} on:change={handleSearch} />
                {model}
            </label>
        {/each}
    </div>-->
    <hr />
    <div>
        <h3>Fahrzeugtypen</h3>
        {#each types as type}
            <label>
                <input type="checkbox" name="model[]" value={type} bind:group={model} on:change={handleSearch} />
                {type}
            </label>
        {/each}
    </div>
    <!--<hr />
    <div>
        <h3>Monatliches Budget ({price[0]},- € - {price[1]},- €)</h3>
        <RangeSlider name="model[]" range pushy min={200} bind:values={price} max={1500} float prefix="€" id="price" on:stop={filterOnPrice}  on:change={handleSearch}  /> 
    </div>-->
    <hr />
    <div>
        <h3>Ausstattung</h3>
        {#each features as feature}
            <label>
                <input type="checkbox" name="model[]" value={feature} bind:group={model} on:change={handleSearch} />
                {feature}
            </label>
        {/each}
    </div>
    <hr />
    <div>
        <h3>Kraftstoff</h3>
        {#each fuels as fuel}
            <label>
                <input type="checkbox" name="model[]" value={fuel} bind:group={model} on:change={handleSearch}  />
                {fuel}
            </label>
        {/each}
    </div>
    <!--
    <hr />
    <div>
        <h3>Getriebe</h3>
        {#each transmissions as transmission}
            <label>
                <input
                    type="checkbox"
                    name="model[]"
                    value={transmission}
                    bind:group={model}
                    on:change={handleSearch} 
                />
                {transmission}
            </label>
        {/each}
    </div>-->
</form>
{/if}

<style>
    label {
        background-color: #00a6e2;
        padding: 10px;
        margin: 2px;
        line-height: 30px;
        min-width: 140px;
        border-radius: 20px;
        text-align: center;
        display: inline-block;
    }
    label input {
        background-color: transparent;
        color: #00a6e2;
        display: none;
        border: 0;
    }
    label input:checked {
        background-color: transparent;
        zoom: 1.2;
        display: inline-block;
    }
    hr {
        min-height: 20px;
        padding: 4px 0;
        margin-bottom: 12px;
        margin: 2px;
    }
    h3 {
        font-size: 18px;
        padding: 10px 4px;
    }
</style>