<script>
  // core components
  import Grid from "./CardGridMain.svelte";
  export let filtered = {};
  let data = [];
  let promise = Promise.resolve([]);

  //Neu
  async function getPostData() {
    let result;
    return (await self
      .fetch("https://beresa.machs-mit-marketing.de/abosite/teaser/get/all")
      .then((res) => {
        if (res.ok) {
          result = res.json();
        } else {
          throw new Error(data);
        }
        return result;
      }));
  }

  $: filtered = (() => {
      promise = getPostData();
      return promise;
  })();

  $: data = filtered;

</script>

{#await promise}
  <p class="text-center">Daten werden geladen...</p>
{:then filtered}
  <Grid data={data.length >= 0 ? data : filtered.data} />
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}