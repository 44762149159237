<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // No Layout Pages
  import Index from "./views/Index.svelte";

  export let url = "";
</script>

<Router url="{url}">
  <Route path="/abo" component="{Index}" />
  <Route path="/das-auto-abo" component="{Index}" />
</Router>
