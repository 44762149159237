<script>
    import { getContext } from 'svelte';
    import CardModalPopup from './CardModalPopup.svelte';
    let confirmTerms = true;
    export let contentData = [];
    export let mainCurency = null;
    export let summary = null;
    export let summaryOnce = null;
    export let startgebuehr = null;
    export let packagesData = null;
    const { open } = getContext('simple-modal');
    const showSurprise = () => open(CardModalPopup, { contentData: contentData, mainCurency: mainCurency, summary: summary, summaryOnce: summaryOnce, startgebuehr: startgebuehr, packagesData: packagesData });
    
</script>
<label for="confirm"><input type="checkbox" id="confirmTerms" on:change={() => {
    confirmTerms =! confirmTerms;
}}> <a href="/agb" target="_blank" noreferrer>AGBs gelsen und akzeptiert.</a></label>  
<p><button id="inquiryForm" on:click={showSurprise} disabled={confirmTerms}>Anfrage senden</button></p>

<style>
    #confirmTerms {
        margin: -5px 4px 0px 0px;
    }
    #inquiryForm {
        font-family: "BeresaSans-Demi", sans-serif;
        font-size: 16px;
        line-height: 24px;
        min-width: 180px;
        background-color: #00a6e2;
        color: #ffffff;
        cursor: pointer;
        margin: 12px 0;
        padding: 12px;
        border-radius: 12px;
    }
    #inquiryForm:disabled {
        background-color: #cccccc;
        color: #ffffff;
        cursor: not-allowed;
    }
</style>