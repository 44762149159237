<script>
    import { fade, crossfade, scale } from "svelte/transition";
    import Modal from 'svelte-simple-modal';
    import CardImageSlider from "./CardImageSlider.svelte";
    import CardOptionButton from "./CardOptionButton.svelte";
    import CardModal from './CardModal.svelte';
 
    import isset from "isset-php";
    import { dataset_dev } from "svelte/internal";
    export let getValideValue = null;
    export let getValideList = null;
    export let detailOverview = 0;
    export let einheitVerbrauch = null;
    export let bezeichnungVebrauch = null;
    export let contentData = [];
    export let mainCurency = 279;
    export let summary = null;
    export let summaryOnce = null;
    export let packagesData = null;
    export let startgebuehr = 0;
  
    function isSmart(str) {
      return /smart/.test(str);
    }

    const [send, receive] = crossfade({
      duration: 200,
      fallback: scale,
    });
  
    let p = 0;
    let o = 0;
    $: summary = parseFloat(p);
    $: summaryOnce = parseFloat(o);
    $: packagesData = packagesData;
  </script>
<section class="pb-20 block">
    <div class="lg:pt-6 pt-6 px-12" transition:fade>
      <div class="mb-8 sm:px-4 text-left">
        <strong class="btn-back text-left" on:mousedown={() => detailOverview = 0}><span class="icon"> &#x21e6;&nbsp; </span> Zurück zur Übersicht</strong>
      </div>
      <div class="flex flex-wrap-reverse pt-4">
        <div class="align-top pt-6 lg:w-6/12 px-4">
          <div class="top-0">
            <div class="bg-white shadow-sm rounded-t-lg">
              <CardImageSlider items = {getValideValue(contentData?.attributes?.Bildergalerie, 'data')} />
            </div>
          </div>
          <div class="flex-col">
            <div class="flex mb-8">
              <div class="lg:w-6/12 mb-8 ">
                <div class="pt-6 text-left">
                  <p><strong>Basisinformationen</strong></p>
                  <p class="mt-2">Leistung<br />{getValideValue(contentData?.attributes?.Informationen, 'Leistung')} {einheitVerbrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))}</p>
                  <p class="mt-6">Antrieb<br />{getValideValue(contentData?.attributes?.Informationen , 'Antrieb')}</p>
                  <p class="mt-6">Anzahl Türen<br />{getValideValue(contentData?.attributes?.Informationen, 'Turen')}</p>
                  <p class="mt-6">Farbe<br />{getValideValue(contentData?.attributes?.Informationen, 'Farbe')}</p>
                </div>
                <div class="flex min-w-0 break-words mb-8">
                  <div class="mt-6 lg:w-12/12 item text-left">
                    <strong>Verbrauch und Emissionen</strong>
                    <p class="mt-2">
                        <small>{bezeichnungVebrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} kombiniert</small><sup>2,3</sup><br />{getValideValue(contentData?.attributes?.Emissionenp, 'CO2_Emission_kombiniert')} {einheitVerbrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} / 100Km
                    </p>
                    <p class="mt-6">
                        <small>{bezeichnungVebrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} innerorts</small> <sup>2,3</sup><br />{getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_innerorts')} {einheitVerbrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} / 100Km
                    </p>
                    <p class="mt-6">
                        <small>{bezeichnungVebrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} außerorts</small> <sup>2,3</sup><br />{getValideValue(contentData?.attributes?.Emissionen, 'Kraftstoffverbrauch_ausserorts')} {einheitVerbrauch(getValideValue(contentData?.attributes?.Informationen , 'Antrieb'))} / 100Km
                    </p>
                    <p class="mt-6">
                        <small>Umweltplakette</small><br />{getValideValue(contentData?.attributes?.Emissionen, 'Umweltplakette')}
                    </p>
                    <p class="mt-6">
                        <small>CO₂-Emission kombiniert</small> <sup>2,3</sup><br />{getValideValue(contentData?.attributes?.Emissionen, 'CO2_Emission_kombiniert')} g/km
                    </p>
                    <p class="mt-6">
                        <small>CO₂-Effizienz</small><br />
                        {#if isset(() => contentData?.attributes?.Emissionen.Effizienzklasse)}
                        <img class="eff" src="https://beresa.machs-mit-marketing.de/assets/img/enefkl-{getValideValue(contentData?.attributes?.Emissionen,'Effizienzklasse')}.svg" alt="{contentData?.attributes?.Emissionen.Energieklasse}" />
                        {:else}
                        -
                        {/if}
                    </p>
                  </div>
                </div>
              </div>
              <div class="lg:w-6/12 mb-8">
                <div class="pt-6 text-left">
                  <strong>Ausstattungshighlights*</strong>
                    {#if getValideList(contentData?.attributes,'Ausstattung').length > 0}
                        <ul class="mt-2 mb-4 p-12 text-blueGray-500">
                        {#each getValideList(contentData?.attributes,'Ausstattung') as [k, v], index}  
                            {#if v === true}
                            <li class="mt-0">&#x2713; {k.replaceAll("_", " ")}</li>
                            {/if}
                        {/each}
                        </ul>
                    {:else}
                        <p><small>Es liegen aktuell keine Informationen vor.</small></p>
                    {/if}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="align-top pt-6 lg:w-6/12 px-4 text-left">
          <div class="top-0">
            <h1 class="text-xl pricetag font-semibold text-left">
              {contentData?.attributes?.Titel}
            </h1>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-4/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">BASISPREIS&nbsp;</h2>
              <h2 class="text-xl pricetag font-semibold text-left">zzgl. Startgebühr&nbsp;</h2>
            </div>
            <div class="pt-6 lg:w-2/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">{mainCurency = (getValideValue(contentData?.attributes, 'Basispreis') ?? 0)} €</h2>
              <h2 class="text-xl pricetag font-semibold text-left">{startgebuehr = ((getValideValue(contentData?.attributes, 'Startgebuehr') === '-' ? 0 : getValideValue(contentData?.attributes, 'Startgebuehr')))} € <strong>(einmalig)</strong></h2>
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Kilometerpaket</h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "1",
                    name: "850 km",
                    value: "0",
                    option: "0 € / Monat",
                  },
                  {
                    type: "month",
                    elem: "1",
                    name: "1250 km",
                    value: isSmart(contentData?.attributes?.Titel) ? 45 : 69,
                    option: (isSmart(contentData?.attributes?.Titel) ? "45" : "69") + " € / Monat",
                  },
                  {
                    type: "month",
                    elem: "1",
                    name: "1650 km",
                    value: isSmart(contentData?.attributes?.Titel) ? 90 : 138,
                    option: (isSmart(contentData?.attributes?.Titel) ? "90" : "138") + " € / Monat",
                  },
                  {
                    type: "month",
                    elem: "1",
                    name: "2050 km",
                    value: isSmart(contentData?.attributes?.Titel) ? 135 : 207,
                    option: (isSmart(contentData?.attributes?.Titel) ? "135" : "207") + " € / Monat",
                  },
                  /*{
                    type: "month",
                    elem: "1",
                    name: "2450 km",
                    value: isSmart(contentData?.attributes?.Titel) ? 207 : 276,
                    option: (isSmart(contentData?.attributes?.Titel) ? "207" : "276") + " € / Monat",
                  }*/
                ]}
              />
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Laufzeit</h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "2",
                    name: "24 Monate",
                    value: "0",
                    option: "0 € / Monat",
                  },
                  {
                    type: "month",
                    elem: "2",
                    name: "12 Monate",
                    value: "20",
                    option: "20 € / Monat",
                  },
                  {
                    type: "month",
                    elem: "2",
                    name: "6 Monate",
                    value: "50",
                    option: "50 € / Monat",
                  },
                  {
                    type: "month",
                    elem: "2",
                    name: "3 Monate",
                    value: "80",
                    option: "80 € / Monat",
                  },
                ]}
              />
            </div>
          </div>
          <div class="top-0 mb-4">
            <hr />
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Zusatzfahrer <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summaryOnce={o}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "once",
                    elem: "3",
                    name: "kein Zusatzfahrer",
                    value: "0",
                    option: "",
                  },
                  {
                    type: "once",
                    elem: "3",
                    name: "Zusatzfahrer",
                    value: "179",
                    option: "179 € einmalig",
                  },
                ]}
              />
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Auslandsfahrten <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "4",
                    name: "keine Auslandsfahrten",
                    value: "0",
                    option: "",
                  },
                  {
                    type: "month",
                    elem: "4",
                    name: "Auslandsfahrten",
                    value: "10",
                    option: "10 € / Monat",
                  },
                ]}
              />
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">
              Ladekabelmiete <strong>(Optional)</strong>
            </h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summary={p}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "month",
                    elem: "5",
                    name: "kein Ladekabel",
                    value: "0",
                    option: "",
                  },
                  {
                    type: "month",
                    elem: "5",
                    name: "Ladekabelmiete",
                    value: "10",
                    option: "10 € / Monat",
                  },
                ]}
              />
            </div>
          </div>
          <div class="top-0">
            <h2 class="text-xl font-semibold text-left">Lieferung <strong>(Optional)</strong></h2>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <CardOptionButton
                bind:summaryOnce={o}
                bind:packagesData={packagesData}
                items={[
                  {
                    type: "once",
                    elem: "6",
                    name: "keine Lieferung",
                    value: "0",
                    option: "",
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 100 km",
                    value: "349",
                    option: "349 € einmalig",
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 200 km",
                    value: "399",
                    option: "399 € einmalig",
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 300 km",
                    value: "499",
                    option: "499 € einmalig",
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 500 km",
                    value: "699",
                    option: "699 € einmalig",
                  },
                  {
                    type: "once",
                    elem: "6",
                    name: "bis 700 km",
                    value: "749",
                    option: "749 € einmalig",
                  }
                ]}
              />
            </div>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-6/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">GESAMTSUMME<br /><strong>inklusive MwSt.</strong></h2>
            </div>
            <div class="pt-6 lg:w-4/12 item text-right">
              <h2 class="text-xl pricetag font-semibold text-right"><strong>einmalig</strong>&nbsp;</h2>
              <h2 class="text-xl pricetag font-semibold text-right"><strong>monatlich</strong>&nbsp;</h2>
            </div>
            <div class="pt-6 lg:w-2/12 item text-left">
              <h2 class="text-xl pricetag font-semibold text-left">{startgebuehr + summaryOnce} €</h2>
              <h2 class="text-xl pricetag font-semibold text-left">{mainCurency + summary} €</h2>
            </div>
          </div>
          <div class="flex min-w-0 break-words mb-8">
            <div class="pt-6 lg:w-12/12 item text-left">
              <Modal><CardModal {contentData} {summary} {summaryOnce} {startgebuehr} {mainCurency} {packagesData}/></Modal>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:w-12/12 px-4 text-left">
        <div class="top-0"><small>*Abweichungen vom abgebildeten Fahrzeug und aufgelisteten Ausstattungen möglich.</small></div>
      </div>
    </div>
</section>

<style>
h1 {
    font-size: 1.75rem;
    margin: 0 1rem 0 0;
    display: flex;
    align-items: center;
}

img.eff {
    width: 280px;
    height: auto;
    margin: -0.25rem -1.0rem;
    background-color: transparent;
    padding: 0;
}

.font-semibold {
  color: #000000 !important;  
}

.pricetag {
    font-family: "BeresaSans-Demi", sans-serif;
    font-size: 20px !important;
    line-height: 24px !important;
    color: #000000 !important;
    cursor: pointer;  
}
h1.pricetag {
    font-size: 22px !important;
    line-height: 24px !important;
    color: #00a6e2 !important; 
}
h2.pricetag {
    font-size: 18px !important;
    line-height: 22px !important;
    color: #00a6e2 !important;
}

.btn-back {
    font-family: "BeresaSans-Demi", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #00a6e2;
    cursor: pointer;
}

.flex {
    display: flex;
}
</style>