<script>
    import Swiper from 'swiper/esm/svelte/swiper.svelte';
    import SwiperSlide from 'swiper/esm/svelte/swiper-slide.svelte';
    import { Autoplay, Navigation, Pagination } from 'swiper/core';
    export let items = [];
</script>

<Swiper className="swiper-container"
    loop={true}
    spaceBetween={20}
    slidesPerView={1}
    autoplay={{
        delay: 10000,
        disableOnInteraction: true
    }}
    navigation={true}
    pagination={true}
    modules={[ Autoplay, Navigation, Pagination ]}>
    {#if Object.entries(items).length > 0}
        {#each Object.entries(items) as [key, value], i ([key, value])}
            {#if typeof value?.attributes?.formats?.thumbnail?.url !== 'undefined' } 
            <SwiperSlide className="swiper-slide">
                <img style="min-height: 420px;" src="https://cms.machs-mit-marketing.de{value?.attributes?.formats?.thumbnail?.url}" srcset="https://cms.machs-mit-marketing.de/{value?.attributes?.formats?.small?.url}" loading="lazy" class="w-auto" alt="{value.alternativeText}" />
            </SwiperSlide>
            {/if}
        {/each}
    {/if}
</Swiper>

<style>
    :global(.swiper-container) {
        margin-left: auto;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
        max-width: 640px !important;
    }
</style>